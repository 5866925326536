
export default {

  validateLogo(logo,avatar){
    if(!logo || !avatar){
      return false;
    }
    if(!logo.length && !avatar.length){
      return false;
    }
    return true;
  },

  validateName(name){
    var hasError = false;
    if(name.length<4 || name.length>55){
      return 1;
    }else if(!(new RegExp('^([a-zA-Z0-9.\'-\\s])+$','i')).test(name)){
      return 2;
    }
    return 0;
  },

  wordCounter(desc){
    var words = (desc || '').split(/(?:,| )+/);
    return words.length;
  },
  
  validateDesc(desc,str_prev){
    var textarea_change = str_prev != desc ? 1 : 0;
    if(textarea_change && (this.wordCounter(desc) < 100 || this.wordCounter(desc) > 500)){
      return false;
    }
    return true;
  },

  validateTypes(types){
    if(types.length < 1){
      return false;
    }
    return true;
  },

  validateSlogan(slogan){
    if(slogan && (slogan.length<10 || slogan.length>145)){
      return false;
    }
    return true;
  },

  validateEmail(email){
    if(!(new RegExp('^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$','i')).test(email)){
      return false;
    }
    return true;
  },

  validateUrlWebSite(url_website){
    if(!(new RegExp('^(?:(?:https?|http):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]+$','i')).test(url_website)){
      return false;
    }
    return true;
  },

  validatePhotos(items_photo){
    if(items_photo.length < 1 || items_photo.length > 30){
      return false;
    }
    return true;
  },

}